import React, { useState, useEffect, useMemo, Fragment } from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

import Notification from "components/app/notification";
import Grid from "components/app/grid";
import GridContent from "components/app/gridContent";
import CenteredPage from "components/app/centeredPage";
import Section from "components/app/Section";
import TABLE from 'components/app/table_html/table';
import TR from 'components/app/table_html/tr';
import TD from 'components/app/table_html/td';
import TH from 'components/app/table_html/th';

import Loader from "components/app/loader";


import { NotificationWrapper } from "components/app/notification/style";


import { HeadComponent } from "components/head";
import { SchoolAppStyle } from "GlobalStyle";
import { BACKEND_URL } from 'actions/url';
import { appServices2} from 'api/schoolApp/services';
import { filterObjectByKey} from 'components/app/actions/array-utils/Filter';
import { sortByName } from 'components/app/actions/array-utils/Sort';
import { numberDigit } from 'components/app/actions/numberDigit';
import { nth } from 'components/app/actions/nth';


const ResultChecker = (props) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialTraitState = useMemo(() => ({id:0, uid:'', commendation:"", manners:"", attentiveness:'', honesty:'', neatness:'', politeness:'', punctuality:''}), []);
    const initialTraitPsychState = useMemo(() => ({id:0, uid:'', handwriting:'', sports:'', drawing_and_painting:'', art_and_crafts:''}), []);
    const initialMalletState = useMemo(() => ({pin:''}), []);
    const initialStudentResult = useMemo(() => ({id:'', key:'', access_exam:{id:'', scores:[], attendance:[], trait_score:{id:0}, is_uploaded:false}}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);
    const [malletState, setMalletState] = useState(initialMalletState);
    const [studentResult, setStudentResult] = useState(initialStudentResult);

    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadData, setLoadData] = useState(false);


    const handleChange = e => {
        setMalletState({...malletState, [e.target.name]:e.target.value});
    }
    const onSubmit = e => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setSubmit(true);
        setLoadData(false);
    }

    useEffect(() => {
        if(submit && malletState.pin){
            setSubmit(false);
            setError({data:[], title:''});
            setLoading(true);
            appServices2.getAccessResult(malletState.pin).then(res => {
                setStudentResult(res.data);
                setLoadData(true);
                setLoading(false);
            }).catch(e => {
                setError({title:'Internal Server Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']});
                setStudentResult({id:0});
                setLoading(false);
                setLoadData(false);
            })
        }
    }, [submit, malletState]);
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertScoresEntryData = () => {
        const all_scores = studentResult.access_exam.scores[0].subject_offering.scores_temp;
        const stud_name_width = (100 - ((all_scores.length) * 5) + 10);
        const split_str = (str="", search="") => {
            const spl = str.split(search)
            if(spl.length > 0){
                return spl.map((item) => (`${item} `));
            }
            return str;
        }
        const all_scores_etable = studentResult.access_exam.scores[0].subject_offering.scores_temp;
        const trait_score = studentResult.access_exam.trait_score.id ? filterObjectByKey(studentResult.access_exam.trait_score, initialTraitState) : initialTraitState;
        const trait_psych_score = studentResult.access_exam.trait_score.id ? filterObjectByKey(studentResult.access_exam.trait_score, initialTraitPsychState) : initialTraitPsychState;
        const full_name = studentResult.access_exam.user_last_name ? `${studentResult.access_exam.user_last_name} ${studentResult.access_exam.user_first_name}` : `${studentResult.access_exam.last_name} ${studentResult.access_exam.first_name}`;
        return (
            <>
            <div className='no-print'>
            <div className='disp-flex'>
                <div style={{marginBottom:'4px', flex:'0 0 20%', maxWidth:'20%'}}>
                    <img className='br-circle img' style={{width:'50px', height:'50px'}} src={`${BACKEND_URL}${studentResult.school_icon}`} alt={'School_icon'}  />
                </div>
                <div style={{marginBottom:'4px', flex:'0 0 80%', maxWidth:'80%'}}>
                    <span className='disp-block app-text-color fw-700 font-med text-capitalize'> {studentResult.school_name} (<span className='text-upper'>{studentResult.school_title}</span>)</span>
                    <span className='disp-block app-text-color fw-700 font-med text-capitalize'> {studentResult.session} {studentResult.term_name}  Term Terminal Report Card</span>
                </div>
            </div>
            <div className='disp-flex'>
            <div className="flex-100 padd-4px" style={{marginBottom:'4px'}}>
                <div style={{position:'absolute', right:'20px'}}>
                    <img className='br-circle img' style={{width:'70px', height:'80px'}} src={`${BACKEND_URL}${studentResult.access_exam.user_profile_pic ? studentResult.access_exam.user_profile_pic : studentResult.access_exam.profile_pic}`} alt={'student'} />
                </div>
                <div className=' disp-flex wrap'>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>ADM NO:. </label> <span className='app-text-color fw-600 font-very-small'>{numberDigit(studentResult.access_exam.adm_no, 4)}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Fullname:. </label> <span className='app-text-color fw-500 text-capitalize font-very-small'>{full_name}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Class: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.class_name}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Date Of Birth: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.dob}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Session Interval: </label> <span className='app-text-color fw-500 font-very-small'>{studentResult.session}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Term: </label><span className='app-text-color fw-500 font-very-small text-capitalize'>{studentResult.term_name}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>No. In Class: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.tot_current_student}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Attendance Present: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.tot_attend_present}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Attendance Absent: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.tot_attend_absent}</span>
                    </div>
                </div>
            </div>
            </div>
            {studentResult.access_exam.trait_score.id && (
                <div className='disp-flex wrap'>
                    <div className='flex-50-line padd-4px'>
                        <span className='font-slightly-small app-text-color fw-600'>PIN:</span><span className='font-very-small app-text-color fw-500'> {studentResult.key}</span> <span className='fw-500 app-text-color font-very-small'>({studentResult.entries+1}/{studentResult.tot_entries})</span>
                    </div>
                    <div className='flex-50-line align-right padd-4px'>
                        <label className={`font-very-small fw-600 app-text-color `}>Final Position: </label> <span className={`font-med fw-700 app-text-color align-center`}>{studentResult.access_exam.final_pos}{parse(nth(studentResult.access_exam.final_pos, true))}</span>
                    </div>
                </div>
            )}
            {/* {isStaffUploaded && <div className='flex-100 padd-4px'><span className='disp-block align-center font-small fw-600 green'><i>**Data Has Been Uploaded Successfully</i></span></div>} */}
            <div className='disp-flex wrap'>
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH style={{padding:'5px', width:`${stud_name_width}%`}}><span className="font-slightly-small">Subject</span></TH>
                            {all_scores.map((item_j, j) => (
                                <Fragment key={j}>
                                    <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">{item_j.short_name}</span></TH>
                                </Fragment>
                            ))}
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Exam</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Total</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Grade</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Remark</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Pos</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Teacher</span></TH>
                            {/* <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">GRD</span></TH> */}
                        </TR>
                    </thead>
                    <tbody>
                        {sortByName(studentResult.access_exam.scores, 'subject_name', true).map((item, i) => {
                            // const full_name = item.subject_offering.user_last_name ? `${item.subject_offering.user_last_name} ${item.subject_offering.user_first_name}` : `${item.subject_offering.last_name} ${item.subject_offering.first_name}`;
                            const exam_score = item.scores
                            const scores = item.subject_offering.scores_temp;
                            const teacher_name = item.scores.length > 0 ? `${item.scores[0].created_by_last_name} ${item.scores[0].created_by_first_name}`: (item.subject_offering.scores_temp.length > 0 ? `${item.subject_offering.scores_temp[0].created_by_last_name} ${item.subject_offering.scores_temp[0].created_by_first_name}` : '');
                            const insertScores = () => {
                                return all_scores_etable.filter((it) => it.short_name.toLowerCase() !== "exam").map((item_k, j) => {
                                    let item_jj = scores.filter((it) => it.score_type === item_k.id || it.short_name === item_k.short_name)
                                    let item_j = item_jj.length > 0 ? item_jj[0] : {id:0, is_absent:false, mark:''}
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{item_j.is_absent ? 'ABS' : parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            const insertExamScore = () => {
                                return exam_score.map((item_j, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            return(
                                <Fragment key={i}>
                                    <TR>
                                        <TD align='center'><span className="font-slightly-small fw-600 app-text-color text-capitalize">{item.subject_name}</span></TD>
                                        {insertScores()}
                                        {item.is_present ? insertExamScore() : (<TD><span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>ABS</span></TD>)}
                                        <TD>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.tot_mark}</span>}</TD>
                                        <TD>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.curr_grade}</span>}</TD>
                                        <TD>{<span className={`disp-block font-super-small fw-600 app-text-color align-center`}>{item.curr_grade_type}</span>}</TD>
                                        <TD>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.pos}</span>}</TD>
                                        <TD>{<span className={`disp-block font-super-small fw-600 app-text-color align-center`}>{teacher_name}</span>}</TD>
                                        {/* <TD>{<span className={`disp-block font-slightly-small fw-700 app-text-color align-center`}>{item.curr_grade}</span>}</TD>           */}
                                    </TR>    
                                </Fragment>
                            )

                        })}
                        {studentResult.access_exam.trait_score.id && (
                            <>
                            <TR>
                                {all_scores_etable.map((it, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <TD></TD>
                                        </Fragment>
                                    )
                                })}
                                <TD></TD>
                                <TD colspan={3}><span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{studentResult.access_exam.fin_mark}</span></TD>
                            </TR>
                            <TR>
                                <TD colspan={7}>
                                    <div className='disp-block align-center'>
                                        <label className={`font-very-small fw-600 app-text-color `}>Final Average: </label> <span className={`font-slightly-small fw-700 app-text-color align-center`}>{studentResult.access_exam.fin_avr}</span>
                                    </div>
                                </TD>
                            </TR>
                            
                            </>
                        )}
                    </tbody>
                </TABLE>
            </div>
            </div>
            <div className='disp-block align-right'>
                <span className='app-text-color fw-600 font-very-small'>Total Registered Subjects: </span><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.tot_reg_subjects}</span>
            </div>
            <div className='disp-flex wrap' style={{width:'100%'}}>
                <div className='flex-90'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'5px', width:`90%`}}><span className="font-slightly-small">Effective Traits</span></TH>
                                <TH style={{padding:'5px'}}><span className="font-slightly-small">Rated</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(trait_score).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD><span className="font-slightly-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD>
                                                <span className='font-slightly-small fw-600'>{trait_score[item]}</span>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
                <div className='flex-90'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'5px', width:`90%`}}><span className="font-slightly-small">PSYCHOMOTOR</span></TH>
                                <TH style={{padding:'5px'}}><span className="font-slightly-small">Rated</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(trait_psych_score).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD><span className="font-slightly-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD>
                                                <span className='font-slightly-small fw-600'>{trait_psych_score[item]}</span>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
                
            </div>
            <div className='disp-flex wrap'>
            {studentResult.access_exam.trait_score.id && (
                    
                    <div className='flex-100'>
                        <TABLE>
                            <tbody>
                                {studentResult.access_exam.is_init_promote && (
                                            <>
                                            <TR>
                                                <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Status</span></TD>
                                                <TD style={{width:'70%'}}>{studentResult.access_exam.is_promote ? <span className='font-slightly-small green fw-600'>PROMOTION</span> : <span className='font-slightly-small red fw-600'>DEMOTION</span>} <br /> {studentResult.access_exam.is_promote_to_next_class && (
                                                    <div className='disp-block'>
                                                        <span className='disp-block font-very-small app-text-color align-center fw-600 '>Class Promoted: {studentResult.access_exam.prom_class_name}</span>
                                                    </div>
                                                )} </TD>
                                            </TR>
                                        </>
                                    ) 
                                }
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Management's Remark</span></TD>
                                    <TD style={{width:'70%'}}><span className='font-very-small'>{studentResult.access_exam.principal_remark}</span></TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Form Teacher's Name</span></TD>
                                    <TD style={{width:'70%'}}><span className='font-very-small'>{studentResult.access_exam.trait_score.created_by_last_name} {studentResult.access_exam.trait_score.created_by_first_name}</span></TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Form Teacher's Remark</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.access_exam.trait_score.remark}</TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Voskool's Remark</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.access_exam.voskool_remark}</TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Vacation Date</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.access_exam.vac_date}</TD>
                                </TR>
                            </tbody>
                        </TABLE>
                    </div>
                    
                    )}
            </div>
            </div>
            </>
        )
    }
    const insertScoresEntryPrintData = () => {
        const all_scores = studentResult.access_exam.scores[0].subject_offering.scores_temp;
        const stud_name_width = (100 - ((all_scores.length) * 5) + 10);
        const split_str = (str="", search="") => {
            const spl = str.split(search)
            if(spl.length > 0){
                return spl.map((item) => (`${item} `));
            }
            return str;
        }
        const all_scores_etable = studentResult.access_exam.scores[0].subject_offering.scores_temp;
        const trait_score = studentResult.access_exam.trait_score.id ? filterObjectByKey(studentResult.access_exam.trait_score, initialTraitState) : initialTraitState;
        const trait_psych_score = studentResult.access_exam.trait_score.id ? filterObjectByKey(studentResult.access_exam.trait_score, initialTraitPsychState) : initialTraitPsychState;
        const full_name = studentResult.access_exam.user_last_name ? `${studentResult.access_exam.user_last_name} ${studentResult.access_exam.user_first_name}` : `${studentResult.access_exam.last_name} ${studentResult.access_exam.first_name}`;
        return (
            <>
            <div className='disp-flex'>
                <div style={{marginBottom:'4px', flex:'0 0 20%', maxWidth:'20%'}}>
                    <img className='br-circle img' style={{width:'50px', height:'50px'}} src={`${BACKEND_URL}${studentResult.school_icon}`} alt={'School_icon'}  />
                </div>
                <div style={{marginBottom:'4px', flex:'0 0 80%', maxWidth:'80%'}}>
                    <span className='disp-block app-text-color fw-700 font-med text-capitalize'> {studentResult.school_name} (<span className='text-upper'>{studentResult.school_title}</span>)</span>
                    <span className='disp-block app-text-color fw-700 font-med text-capitalize'> {studentResult.session} {studentResult.term_name}  Term Terminal Report Card</span>
                </div>
            </div>
            <div className='disp-flex'>
            <div className="flex-100 padd-4px" style={{marginBottom:'4px'}}>
                <div style={{position:'absolute', right:'20px'}}>
                    <img className='br-circle img' style={{width:'70px', height:'80px'}} src={`${BACKEND_URL}${studentResult.access_exam.user_profile_pic ? studentResult.access_exam.user_profile_pic : studentResult.access_exam.profile_pic}`} alt={'student'} />
                </div>
                <div className=' disp-flex wrap'>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>ADM NO:. </label> <span className='app-text-color fw-600 font-very-small'>{numberDigit(studentResult.access_exam.adm_no, 4)}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>Name:. </label> <span className='app-text-color fw-500 text-capitalize font-very-small'>{full_name}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>Class: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.class_name}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>Date Of Birth: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.dob}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>Session Interval: </label> <span className='app-text-color fw-500 font-very-small'>{studentResult.session}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>Term: </label><span className='app-text-color fw-500 font-very-small text-capitalize'>{studentResult.term_name}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>No. In Class: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.tot_current_student}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>Attendance Present: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.tot_attend_present}</span>
                    </div>
                    <div className='flex-30-line align-center-m'>
                        <label className='app-text-color fw-600 font-super-small'>Attendance Absent: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.tot_attend_absent}</span>
                    </div>
                </div>
            </div>
            </div>
            {studentResult.access_exam.trait_score.id && (
                <div className='disp-flex wrap'>
                    <div className='flex-50-line padd-4px'>
                        <span className='font-slightly-small app-text-color fw-600'>PIN:</span><span className='font-very-small app-text-color fw-500'> {studentResult.key}</span> <span className='fw-500 app-text-color font-very-small'>({studentResult.entries+1}/{studentResult.tot_entries})</span>
                    </div>
                    <div className='flex-50-line align-right padd-4px'>
                        <label className={`font-very-small fw-600 app-text-color `}>Final Position: </label> <span className={`font-med fw-700 app-text-color align-center`}>{studentResult.access_exam.final_pos}{parse(nth(studentResult.access_exam.final_pos, true))}</span>
                    </div>
                </div>
            )}
            {/* {isStaffUploaded && <div className='flex-100 padd-4px'><span className='disp-block align-center font-small fw-600 green'><i>**Data Has Been Uploaded Successfully</i></span></div>} */}
            <div className='disp-flex wrap'>
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH style={{padding:'5px', width:`${stud_name_width}%`}}><span className="font-very-small">Subject</span></TH>
                            {all_scores.map((item_j, j) => (
                                <Fragment key={j}>
                                    <TH style={{padding:'5px', width:'4%'}}><span className="font-very-small">{item_j.short_name}</span></TH>
                                </Fragment>
                            ))}
                            <TH style={{padding:'5px', width:'4%'}}><span className="font-very-small">Exam</span></TH>
                            <TH style={{padding:'5px', width:'4%'}}><span className="font-very-small">Total</span></TH>
                            <TH style={{padding:'5px', width:'4%'}}><span className="font-very-small">GRD</span></TH>
                            <TH style={{padding:'5px', width:'4%'}}><span className="font-very-small">Remark</span></TH>
                            <TH style={{padding:'5px', width:'4%'}}><span className="font-very-small">Pos</span></TH>
                            <TH style={{padding:'5px', width:'4%'}}><span className="font-very-small">Teacher</span></TH>
                            {/* <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">GRD</span></TH> */}
                        </TR>
                    </thead>
                    <tbody>
                        {sortByName(studentResult.access_exam.scores, 'subject_name', true).map((item, i) => {
                            // const full_name = item.subject_offering.user_last_name ? `${item.subject_offering.user_last_name} ${item.subject_offering.user_first_name}` : `${item.subject_offering.last_name} ${item.subject_offering.first_name}`;
                            const exam_score = item.scores
                            const scores = item.subject_offering.scores_temp;
                            const teacher_name = item.scores.length > 0 ? `${item.scores[0].created_by_last_name} ${item.scores[0].created_by_first_name}`: (item.subject_offering.scores_temp.length > 0 ? `${item.subject_offering.scores_temp[0].created_by_last_name} ${item.subject_offering.scores_temp[0].created_by_first_name}` : '');
                            const insertScores = () => {
                                return all_scores_etable.filter((it) => it.short_name.toLowerCase() !== "exam").map((item_k, j) => {
                                    let item_jj = scores.filter((it) => it.score_type === item_k.id || it.short_name === item_k.short_name)
                                    let item_j = item_jj.length > 0 ? item_jj[0] : {id:0, is_absent:false, mark:''}
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-very-small fw-500 app-text-color align-center`}>{item_j.is_absent ? 'ABS' : parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            const insertExamScore = () => {
                                return exam_score.map((item_j, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-very-small fw-500 app-text-color align-center`}>{parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            return(
                                <Fragment key={i}>
                                    <TR>
                                        <TD align='center' style={{padding:'0'}}><span className="font-super-small fw-600 app-text-color text-capitalize">{item.subject_name}</span></TD>
                                        {insertScores()}
                                        {item.is_present ? insertExamScore() : (<TD style={{padding:'0'}}><span className={`disp-block font-very-small fw-500 app-text-color align-center`}>ABS</span></TD>)}
                                        <TD style={{padding:'0'}}>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.tot_mark}</span>}</TD>
                                        <TD style={{padding:'0'}}>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.curr_grade}</span>}</TD>
                                        <TD style={{padding:'0'}}>{<span className={`disp-block font-super-small fw-600 app-text-color align-center`}>{item.curr_grade_type}</span>}</TD>
                                        <TD style={{padding:'0'}}>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.pos}</span>}</TD>
                                        <TD style={{padding:'0'}}>{<span className={`disp-block font-super-small fw-600 app-text-color align-center`}>{teacher_name}</span>}</TD>
                                        {/* <TD>{<span className={`disp-block font-slightly-small fw-700 app-text-color align-center`}>{item.curr_grade}</span>}</TD>           */}
                                    </TR>    
                                </Fragment>
                            )

                        })}
                        {studentResult.access_exam.trait_score.id && (
                            <>
                            <TR>
                                {all_scores_etable.map((it, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <TD style={{padding:'0'}}></TD>
                                        </Fragment>
                                    )
                                })}
                                <TD style={{padding:'0'}}></TD>
                                <TD style={{padding:'0'}} colspan={3}><span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{studentResult.access_exam.fin_mark}</span></TD>
                            </TR>
                            <TR>
                                <TD style={{padding:'0'}} colspan={7}>
                                    <div className='disp-block align-center'>
                                        <label className={`font-very-small fw-600 app-text-color `}>Final Average: </label> <span className={`font-slightly-small fw-700 app-text-color align-center`}>{studentResult.access_exam.fin_avr}</span>
                                    </div>
                                </TD>
                            </TR>
                            
                            </>
                        )}
                    </tbody>
                </TABLE>
            </div>
            </div>
            <div className='disp-block align-right'>
                <span className='app-text-color fw-600 font-very-small'>Total Registered Subjects: </span><span className='app-text-color fw-500 font-very-small'>{studentResult.access_exam.tot_reg_subjects}</span>
            </div>
            <div className='disp-flex wrap' style={{width:'100%'}}>
                <div className='flex-50-line'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'3px', width:`90%`}}><span className="font-very-small">Effective Traits</span></TH>
                                <TH style={{padding:'3px'}}><span className="font-very-small">Rated</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(trait_score).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD style={{padding:'2px'}}><span className="font-very-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD style={{padding:'2px'}}>
                                                <span className='font-very-small fw-600'>{trait_score[item]}</span>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
                <div className='flex-50-line'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'3px', width:`90%`}}><span className="font-very-small">PSYCHOMOTOR</span></TH>
                                <TH style={{padding:'3px'}}><span className="font-very-small">Rated</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(trait_psych_score).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD style={{padding:'2px'}}><span className="font-very-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD style={{padding:'2px'}}>
                                                <span className='font-very-small fw-600'>{trait_psych_score[item]}</span>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
                
            </div>
            <div className='disp-flex wrap'>
            {studentResult.access_exam.trait_score.id && (
                    
                    <div className='flex-100'>
                        <TABLE>
                            <tbody>
                                {studentResult.access_exam.is_init_promote && (
                                        <>
                                            <TR>
                                                <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Status</span></TD>
                                                <TD style={{width:'70%'}}>{studentResult.access_exam.is_promote ? <span className='font-slightly-small green fw-600' style={{color:'green'}}>PROMOTION</span> : <span className='font-slightly-small red fw-600' style={{color:'red'}}>DEMOTION</span>} <br /> {studentResult.access_exam.is_promote_to_next_class && (
                                                    <div className='disp-block'>
                                                        <span className='disp-block font-very-small app-text-color align-center fw-600 '>Class Promoted: {studentResult.access_exam.prom_class_name}</span>
                                                    </div>
                                                )} </TD>
                                            </TR>
                                        </>
                                    ) 
                                }
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase', padding:'2px'}}><span className='fw-600 font-very-small'>Management's Remark</span></TD>
                                    <TD style={{width:'70%'}}><span className='font-very-small'>{studentResult.access_exam.principal_remark}</span></TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase', padding:'2px'}}><span className='fw-600 font-very-small'>Form Teacher's Name</span></TD>
                                    <TD style={{width:'70%'}}><span className='font-very-small'>{studentResult.access_exam.trait_score.created_by_last_name} {studentResult.access_exam.trait_score.created_by_first_name}</span></TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase', padding:'2px'}}><span className='fw-600 font-very-small'>Form Teacher's Remark</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.access_exam.trait_score.remark}</TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase', padding:'2px'}}><span className='fw-600 font-very-small'>Voskool's Remark</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.access_exam.voskool_remark}</TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase',padding:'2px'}}><span className='fw-600 font-very-small'>Vacation Date</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.access_exam.vac_date}</TD>
                                </TR>
                            </tbody>
                        </TABLE>
                    </div>
                    
                    )}
            </div>
            </>
        )
    }
    console.log(studentResult)
    return(
        <>
            <HeadComponent title="Student Result Checker" />
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
            <CenteredPage title={!loadData ? "Hey, Wanna Check Your Result...." : ''}>
                {loading && <Loader />}
                {!loadData ? (
                    <form onSubmit={e => onSubmit(e)}>
                        <div className='disp-flex wrap' style={{marginBottom:'8px', marginTop:'25px'}}>
                            <div className='flex-100 form-group'>
                                <label className='fw-600 app-text-sec-color font-slightly-small'>Enter Result Checker Pin</label>
                                <input type={"text"} name="pin" placeholder='Enter Me :)' onChange={e => handleChange(e)} required className='form-control-theme font-very-small' value={malletState.pin} />
                            </div>
                        </div>
                        <div className='disp-flex wrap'>
                            <div className='flex-100 align-center'>
                                <button type="submit" className='mybtn app-bg-color app-bg-text-color'>Check Result</button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <Grid layout='lauto'>
                        <GridContent>
                            <Section>
                                {insertScoresEntryData()}
                                <div className='disp-flex wrap no-print'>
                                    <div className='flex-50-line padd-4px align-center' style={{marginTop:'4px'}}>
                                        <button type="button" className='btn bg-blue font-very-small app-bg-text-color hover-opacity br' onClick={() => {
                                            setStudentResult(initialStudentResult);
                                            setLoadData(false);
                                            setMalletState(initialMalletState);
                                        }}><i className='fas fa-arrow-left'></i> Back</button>
                                    </div>
                                    <div className='flex-50-line padd-4px align-center' style={{marginTop:'4px'}}>
                                        <button type="button" className='mybtn bg-blue font-very-small' onClick={() => window.print()} style={{color:'#ccc'}}>Print</button>
                                    </div>
                                </div>
                                <div className='print'>
                                {insertScoresEntryPrintData()}
                                </div>
                                <div className='print'>
                                    <div className='disp-block align-right'>
                                        <span className='app-text-color fw-600 font-very-small'>For More Details, Visit:</span><Link to={`/${studentResult.school_title}`} className="font-very-small">www.voskool.com/{studentResult.school_title}</Link>
                                    </div>
                                </div>
                            </Section>
                        </GridContent>
                    </Grid>
                )}
            </CenteredPage>
            <SchoolAppStyle />
        </>
    )
}

export default ResultChecker;